@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*  ----------------------------------------------------
0. IMPORT GOOGLE FONTS
-------------------------------------------------------- */
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,700&display=swap");

/* ------------------------------------
    1. GENERAL STYLES
------------------------------------ */
body {
    @apply font-poppins;
    @apply leading-6;
    color: #656565;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
button {
    @apply font-poppins;
    @apply font-bold;
    @apply text-black
}

h1 {
    @apply text-4xl;
}

h2 {
    @apply text-3xl;
}

h3 {
    @apply text-2xl;
}

h4 {
    @apply text-xl;
}

h5 {
    @apply text-lg;
}

h6 {
    @apply text-base;
}

.text-brand {
    @apply text-emerald-500;
}

.background-brand {
    @apply bg-gray-500;
}

.btn-brand {
    @apply inline-block px-8 py-2 mb-2 font-semibold text-white bg-gray-950;
}

.btn-brand:hover {
    @apply duration-500 background-brand
}

.icon-brand {
    @apply inline-block p-4 mb-4 text-3xl text-white background-brand;
}

.listIcon {
    @apply leading-8;
}

.listIcon li {
    @apply flex items-center gap-2;
}

img {
    max-width: 100%;
    height: auto;
}

.section-padding {
    @apply py-20;
}

.title-margin {
    @apply mb-16;
}

/* ------------------------------------
    1. HERO SECTION
------------------------------------ */
/* 1.2 HERO ITEMS */
.hero-icon {
    @apply text-brand;
}

.example-item {
    @apply p-8 mb-16 text-white transition-all border border-gray-300 shadow;
}

/* 1.2 HERO FORM */
.form-group {
    @apply pb-4;
}

.form-group input {
    @apply block w-full px-4 py-1.5 border border-gray-400;
}

.form-group textarea {
    @apply block w-full px-4 py-1.5 border border-gray-400;
}

/* ------------------------------------
    2. FETURE SECTION
------------------------------------ */
/* 2.1 FETURE CART */
.feature-item {
    @apply p-8 transition-all shadow hover:shadow-lg;
}

.feature-item h3 {
    @apply mb-4 font-normal;
}

.feature-item p {
    @apply font-light;
}

/* ------------------------------------
    3. PRICING SECTION
------------------------------------ */
/* 3.1 PRICING CART */
.pricing-item {
    @apply pb-8 bg-white shadow-md;
}

.pricing-item h3 {
    @apply py-4 text-white uppercase background-brand;
}

.pricing-item h4 {
    @apply mt-5 mb-5 text-3xl;
}

/* 3.2 PRICING ITEMS */
.pricing-items {
    @apply text-gray-500;
}

.pricing-items li {
    @apply py-2 border-t border-gray-200;
}

.service-item {
    @apply p-8 mb-16 transition-all border border-gray-300 shadow hover:shadow-lg;
}

.main-banner {
    background: #fff url("assets/slider-bg.jpg") 90% top no-repeat;
    padding-top: 190px;
    overflow: hidden;
}

.rcw-title {
    color: white;
}

.rcw-picker-btn {
    display: none;
}

.rcw-new-message {
    width: calc(100% - 40px) !important;
}

.rcw-close-launcher, .rcw-open-launcher {
    margin: auto !important;
}

.rcw-sender {
    align-items: normal !important;
    gap: 10px;
}

.rcw-conversation-container .rcw-header {
    background-color: black !important;
}

.rcw-launcher {
    background-color: black !important;
}

.rcw-client .rcw-message-text {
    background-color: #D5D5D5 !important;
}

.thm-btn {
    display: inline-block;
    border: none;
    outline: none;
    background: #6541c1;
    background: -webkit-gradient(linear, left top, right top, from(#6541c1), color-stop(98%, #d43396), to(#d43396));
    background: linear-gradient(to right, #6541c1 0%, #d43396 98%, #d43396 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6541c1', endColorstr='#d43396', GradientType=1);
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    border-radius: 28px;
    padding: 15px 57px;
    position: relative;
}

.thm-background {
    background: #6541c1;
    background: -webkit-gradient(linear, left top, right top, from(#6541c1), color-stop(98%, #d43396), to(#d43396));
    background: linear-gradient(to right, #6541c1 0%, #d43396 98%, #d43396 100%);
}

.thm-btn:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    border-radius: 28px;
    background: #fff;
    opacity: 0;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.thm-btn.bordered {
    color: #190A32;
}

.thm-btn.bordered:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    border-radius: 28px;
    background: #fff;
    opacity: 1;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.thm-btn-span {
    background: -webkit-gradient(linear, left top, right top, from(#6541c1), color-stop(98%, #d43396), to(#d43396));
    background: linear-gradient(to right, #6541c1 0%, #d43396 98%, #d43396 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.pricing-section .single-pricing {
    position: relative;
    background: #E8E6E6;
    border-radius: 15px;
}

.pricing-section .single-pricing:before {
    content: '';
    background: #fff;
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    border-radius: 15px;
}

.pricing-section .single-pricing.popular {
    background: #6541c1;
    background: -webkit-gradient(linear, left top, right top, from(#6541c1), color-stop(98%, #d43396), to(#d43396));
    background: linear-gradient(to right, #6541c1 0%, #d43396 98%, #d43396 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6541c1', endColorstr='#d43396', GradientType=1);
    margin-top: -20px;
    margin-bottom: -20px;
}

.feature-title {
    color: #150A33;
    font-size: 32px;
    line-height: 48px;
    margin-top: 25px;
    margin-bottom: 20px;
    font-weight: 500;
}

.feature-content {
    font-size: 16px;
    line-height: 27px;
    color: #656565;
    letter-spacing: .01em;
    margin-bottom: 20px;
}

.circle-image {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.footer {
    background: transparent url("assets/footer-bg.png") center top no-repeat;
    background-size: cover;
}
